import toastr from 'toastr';

window.toast = function (toast) {
    if (toast.success) {
        toastr.options = {"closeButton": true, "progressBar": true};
        // noinspection JSUnresolvedReference
        toastr.success(toast.message);
    } else {
        toastr.options = {"closeButton": true, "progressBar": true};
        // noinspection JSUnresolvedReference
        toastr.warning(toast.message);
    }
};
